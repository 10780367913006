.favoriteButton {
  cursor: pointer;
  font-size: 20px;
}
.favoriteActive {
  color: #f3b416;
}

.favoriteInactive {
  color: #008b8a;
}

@hack: true; @import "/codebuild/output/src3173380739/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";