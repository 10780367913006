.gridContainer {
  width: 100%;
  background: #fafafa;
  padding: 1rem;
  justify-content: center;
}

.container {
  min-height: 270px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plotHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 0.5rem;

  .title {
    margin: 0 1rem 0 0;
  }
  .icon {
    font-size: 1.2rem;
    color: @gray;
  }
  .topRightMenu {
    display: flex;
    align-items: center;
    min-width: 0;
    .expandButton {
      display: flex;
      align-items: center;
      margin-right: 0.5rem;
      color: @text-color-secondary;
      transition: color 0.3s ease-in-out;
      &:hover {
        cursor: pointer;
        color: @teal;
      }
    }
  }
  .topLeftMenu {
    display: flex;
    align-items: center;
  }
}

.menuButton {
  display: block;
  width: 100%;
}

@hack: true; @import "/codebuild/output/src3173380739/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";