.checkboxContainer {
  padding: 10px 4px 18px 4px;
}

.measureSelectedContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 0.5rem;
  padding: 0.5rem 1.5rem;
  background: #e5f1f1;
  border: 1px solid #b3dcdc;
  border-radius: 2px;

  .paragraph {
    margin: 0;
  }
  .clearSelectedMeasuresButton {
    color: @table-link;
    cursor: pointer;
  }
}

@hack: true; @import "/codebuild/output/src3173380739/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";