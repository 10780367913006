.content {
  margin-top: 1rem;

  .title {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  .table {
    margin: 1rem auto 0;
    width: min(1200px, 100%);
  }
  .image {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 0.5rem;
  }
  .requestLink {
    color: @teal;

    &:hover {
      text-decoration: underline !important;
    }
  }
  .overDue {
    color: red;
  }
  :global(.ant-tag) {
    height: 22px;
  }
  :global(.ant-image) {
    margin-right: 1rem;
  }
}
.notDefined {
  color: @gray;
}

@hack: true; @import "/codebuild/output/src3173380739/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";