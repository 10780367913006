.container {
  height: 100%;
  .card {
    position: relative;
    padding: 44px 19px 19px;
  }
  .spin {
    position: absolute;
    z-index: 100;
    top: calc(50% - 1.5rem);
    left: 50%;
  }
}

.plotHeader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 14px 8px 14px;

  transition: all 0.3s;
  &:global(.draggableHandle)&:hover {
    cursor: move;
    background-color: #e6e6e6;
  }

  .infoContainer {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .title {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .icon {
    font-size: 1.2rem;
    color: @gray;
  }
  .topRightMenu {
    display: flex;
    gap: 12px;
    align-items: center;
    min-width: 0;
    flex-shrink: 0;
    .annotationButton {
      display: flex;
      width: 22px;
      min-width: 22px;
      height: 22px;
      align-items: center;
      justify-content: center;
      padding: 0 !important;
      background-color: @white !important;
      font-size: 0.75rem;
      padding-bottom: 1px;
    }
    .expandButton {
      display: flex;
      align-items: center;
      margin-right: -0.3rem;
      color: @text-color-secondary;
      transition: color 0.3s ease-in-out;
      &:hover {
        cursor: pointer;
        color: @teal;
      }
    }
  }
}

:global {
  .react-grid-item > .react-resizable-handle.react-resizable-handle-se {
    bottom: 2px !important;
    right: 2px !important;
  }
}

@hack: true; @import "/codebuild/output/src3173380739/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";