.container {
  height: 100%;
  margin-bottom: 1.3rem;
  @media screen and (max-width: @screen-sm) {
    padding: 2px;
  }

  .plotAnnotationContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &.annotationActive {
      .annotationContainer {
        width: 420px;
        margin-left: 0.5rem;
        transform: translateX(0);
        opacity: 1;
      }
      .headerPlotContainer {
        width: calc(100% - 420px);
      }
    }
    .annotationContainer {
      display: flex;
      flex-flow: column;
      width: 0;
      height: calc(100vh - 94px);
      padding: 0;
      transform: translateX(100%);
      opacity: 0;
      transition: all 0.3s;
      :global(.ant-form) {
        margin-top: 0 !important;
      }
    }
    .headerPlotContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      transition: all 0.3s;

      .plotHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 0.5rem;

        .icon {
          font-size: 1.2rem;
          color: @gray;
        }

        .topRightMenu {
          display: flex;
          align-items: center;
          gap: 6px;

          .addAnnotation {
            display: flex;
            width: 2rem;
            height: 2rem;
            align-items: center;
            justify-content: center;
            font-size: 1rem;
            padding-bottom: 1px;
          }

          .expandButton {
            display: flex;
            align-items: center;
            margin-right: 0.5rem;
            color: @text-color-secondary;
            transition: color 0.3s ease-in-out;

            &:hover {
              cursor: pointer;
              color: @teal;
            }
          }
        }
      }

      .plotHeaderMobile {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .headerTop {
          display: flex;
          justify-content: space-between;

          .icon {
            font-size: 1.2rem;
            color: @gray;
          }
        }
      }

      .plotContainer {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;

        .title {
          margin: 0.5rem;
          font-size: 1.2rem;
          font-weight: 500;
        }

        .addAnnotation {
          :global(.nsewdrag) {
            cursor: url("../../assets/pen-cursor.png") 0 25, copy !important;
          }
        }
      }
    }
  }
}

.menuButton {
  display: block;
  width: 100%;
}

:global(.mentions) {
  background: white;
  &:global(.mentions--multiLine) {
    :global(.mentions__control) {
      min-height: 63px;
      :global(.mentions__highlighter) {
        border: 1px solid transparent;
        padding: 9px;
        min-height: 63px;
      }
      :global(.mentions__input) {
        border: 1px solid #d9d9d9;
        border-radius: 20px;
        padding: 9px;
        outline: 0;
        transition: all 0.3s;
        &:focus {
          border-color: @teal;
          box-shadow: 0 0 0 2px rgba(0, 139, 138, 0.2);
        }
        &:hover {
          border-color: @teal;
        }
      }
    }
    :global(.mentions__suggestions) {
      :global(.mentions__suggestions__list) {
        background-color: white;
        border: 1px solid rgba(0, 0, 0, 0.15);
        font-size: 10pt;
        :global(.mentions__suggestions__item) {
          padding: 5px 25px 5px 15px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.15);
          white-space: nowrap;
          &:global(.mentions__suggestions__item--focused) {
            background-color: @teal-medium;
          }
        }
      }
    }
  }
}

:global(.mentions) {
  background: white;
  &:global(.mentions--multiLine) {
    :global(.mentions__control) {
      min-height: 63px;
      :global(.mentions__highlighter) {
        border: 1px solid transparent;
        padding: 9px;
        min-height: 63px;
      }
      :global(.mentions__input) {
        border: 1px solid #d9d9d9;
        border-radius: 20px;
        padding: 9px;
        outline: 0;
        transition: all 0.3s;
        &:focus {
          border-color: @teal;
          box-shadow: 0 0 0 2px rgba(0, 139, 138, 0.2);
        }
        &:hover {
          border-color: @teal;
        }
      }
    }
    :global(.mentions__suggestions) {
      :global(.mentions__suggestions__list) {
        background-color: white;
        border: 1px solid rgba(0, 0, 0, 0.15);
        font-size: 10pt;
        :global(.mentions__suggestions__item) {
          padding: 5px 25px 5px 15px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.15);
          white-space: nowrap;
          &:global(.mentions__suggestions__item--focused) {
            background-color: @teal-medium;
          }
        }
      }
    }
  }
}

@hack: true; @import "/codebuild/output/src3173380739/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";