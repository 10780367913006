@import "/theme.less";

.container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-image: url("./assets/trees-background.jpeg");
  background-position: center;
  background-size: cover;
  color: @white;

  @media screen and (max-width: @screen-3xl) {
    font-size: 1.3rem;
  }

  .subContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    font-size: 2.75rem;
    gap: 48px;
    .logoLong {
      width: 600px;
    }
    .title {
      font-size: 40px;
      font-weight: 500;
      line-height: 1;
    }
    .textContainer {
      display: flex;
      flex-direction: column;
      text-align: center;

      .subtitle {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 8px;
      }
      .prompt {
        font-size: 18px;
        font-weight: 600;
      }
    }

    .logoContainer {
      box-sizing: border-box;
      width: 160px;
      height: 160px;
      background-color: @white;
      background-size: contain;
      background-repeat: no-repeat;
      border-radius: 100%;
      border: inset 20px transparent;
      filter: drop-shadow(0px 1px 20px rgba(0, 0, 0, 0.1)) drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.1));
    }
    .button {
      width: 12.5rem;
      height: 3.625rem;
      padding: 0.5rem 3.5rem;
      border: none;
      font-size: 26px;
      box-shadow: 0rem 0.125rem 0rem rgba(0, 0, 0, 0.043);
      background-color: @eh2-green;
      border-radius: 1000px;
      color: @white;
      &:hover {
        background-color: darken(@eh2-green, 5%);
      }
    }
  }
}

.hiddenIframe {
  position: absolute;
  height: 0;
  width: 0;
  border: 0;
}

@hack: true; @import "/codebuild/output/src3173380739/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";