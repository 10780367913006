.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: @white;
  border-radius: 4px;
  filter: drop-shadow(0px 1px 20px rgba(0, 0, 0, 0.10)) drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.10));
  .logo {
    width: 72%;
    height: 72%;
  }
}

@hack: true; @import "/codebuild/output/src3173380739/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";