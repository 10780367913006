.assetTreeContainer {
  .assetTitle {
    line-height: 1.4;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  :global(.ant-tree-list) {
    padding-bottom: 3rem;
  }

  .assetTreeDrag {
    :global(.ant-tree-treenode) {
      &[draggable="false"] {
        margin-left: -2rem;
        width: calc(100% + 2rem);
      }
    }
  }
  :global(.ant-tree-treenode-motion) {
    width: 100%;
  }
  :global(.ant-tree-treenode) {
    position: relative;
    display: flex;
    align-items: center !important;
    padding: 0 1rem !important;
    margin-left: -0.5rem;
    width: calc(100% + 0.5rem);
    height: 3rem;
    transition: all;

    &:hover {
      background: @teal-light;
      color: @teal;

      .assetIcon {
        color: @teal;
      }
    }
    &[draggable="true"] {
      :global(.ant-tree-draggable-icon) {
        position: absolute;
        right: 1rem;
        opacity: 1;
      }
    }

    &[draggable="false"] {
      :global(.ant-tree-draggable-icon) {
        visibility: hidden;
      }
    }
    &:global(.drop-target) {
      background: @teal-medium !important;
    }
    &:global(.ant-tree-treenode-selected) {
      background: @teal-light;
      color: @teal;

      .assetIcon {
        color: @teal;
      }
    }

    :global(.ant-tree-drop-indicator) {
      display: none;
    }
    :global(.ant-tree-draggable-icon) {
      color: @teal;
    }

    :global(.ant-tree-node-content-wrapper) {
      position: static !important;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
      }
    }

    :global(.ant-tree-switcher) {
      position: static;
      background: transparent !important;

      &:not(:global(.ant-tree-switcher-noop)) {
        display: flex;
        align-items: center;

        :global(.ant-tree-switcher-icon) {
          z-index: 2;
          padding: 0.5rem;
          border-radius: 50%;

          &:hover {
            background: @teal;
            color: @teal-light;
          }
        }
      }
    }

    :global(.ant-tree-iconEle) {
      display: flex !important;
      align-items: center;
      justify-content: center;
    }

    :global(.ant-tree-node-content-wrapper) {
      display: flex;
      align-items: center !important;
      gap: 0.25rem;
    }
  }

  .highlightedString {
    background: #fb7951;
  }

  .assetIcon {
    color: @blue;
  }
}

@hack: true; @import "/codebuild/output/src3173380739/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";