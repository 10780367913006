@import "/theme.less";

.container {
  width: 100%;
  height: calc(100vh - 48px);
  position: relative;
  display: flex;
  flex-direction: column;

  .spinContainer {
    position: absolute;
    left: 50%;
    top: 5.625rem;
    :global(.ant-spin-dot-item) {
      background-color: @white !important;
    }
  }

  .bannerText {
    position: relative;
    padding: 1.25rem 1.5rem 0.5rem 1.5rem;
    color: white;

    z-index: 3;
    @media screen and (max-width: @screen-xs) {
      padding: 1rem;
      font-size: 26px;
    }

    .bannerTitle {
      font-weight: 500;
      font-size: 30px;
    }
    .bannerSubtitle {
      padding-top: 0.75rem;
      font-size: 16px;
      font-weight: 700;
    }
  }
  .topBanner {
    position: absolute;
    left: 0;
    right: 0;
    height: 11.625rem;
    overflow: hidden;
    margin-bottom: -5.625rem;
    z-index: 0;
    background-size: cover;
    border-radius: 0rem 0rem 1rem 1rem;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .customerContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    padding: 0 24px 24px 24px;
    gap: 16px;

    .modulesContainer {
      display: flex;
      flex-direction: column;
      gap: 16px;
      @media screen and (max-height: 800px) {
        gap: 8px;
      }
      width: 200px !important;
      z-index: 10;
      .cardContainer {
        flex: 1;
        height: auto;
        .appCard {
          height: 100%;
        }
      }
    }
    .plantContainer {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 18px;
      border-radius: 4px;
      background: #fcfdfd;
      max-height: 85vh;
      box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.1), 0px 1px 4px -4px rgba(0, 0, 0, 0.1);
      z-index: 10;
      .plantTitle {
        font-size: 16px;
        font-weight: 700;
      }
      .modelContainer {
        flex-grow: 1;
        float: left;
        position: relative;
        background-color: #fcfdfd;
        overflow: hidden;
      }
    }
  }

  .appCard {
    display: flex;
    flex-shrink: 3;
    height: 10.9375rem;
    background-color: @white;
    overflow: hidden;
    border-radius: 4px;
    box-shadow: @shadow-md;

    transition: all 0.2s ease-out;
    &:hover {
      background-color: #f5f5f5;
    }
  }
  .cardContent {
    display: flex;
    flex-shrink: 2;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    height: 100%;
    box-shadow: @shadow-md;
    @media screen and (max-height: 800px) {
      gap: 0;
    }

    cursor: pointer;
    .appIcon,
    img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 60px;
      font-size: 44px;
      @media screen and (max-height: 800px) {
        width: 44px;
        height: 44px;
        font-size: 38px;
      }
    }
    .appName {
      color: @text-color;
      padding: 0.25rem;
      font-size: 13px;
      font-weight: 500;
      text-align: center;
      @media screen and (max-height: 800px) {
        font-size: 12px;
      }
    }
  }

  .recentModules {
    height: 12.5rem;
    padding: 0 0.75rem;
    .arrow {
      border-radius: 100%;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 2px 0px rgba(0, 0, 0, 0.016);
    }

    .cardContainer {
      padding: 0.75rem;
    }
    :global {
      .ant-carousel {
        .slick-prev,
        .slick-next {
          color: unset;
          font-size: unset;
          z-index: 10;
          &::before {
            content: "";
          }
          &.slick-disabled {
            display: none !important;
          }
        }
        .slick-prev {
          inset-inline-start: -0.1875rem;
        }
        .slick-next {
          inset-inline-end: 0.5rem;
        }
        .slick-slider {
          &:not(.slick-initialized) {
            display: none;
          }
        }
      }
    }
  }

  .contentSection {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 0.5rem 1.5rem;

    @media screen and (max-width: @screen-xs) {
      padding: 0.5rem 1rem;
    }
    .favoritesTitle {
      font-size: 16px;
      font-weight: 700;
    }
  }
}

@hack: true; @import "/codebuild/output/src3173380739/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";