@import "/theme.less";


@toggle-width: 18px;
@toggle-height: 180px;

.toggleBar {
  position: absolute;
  top: calc(50% - @toggle-height / 2);
  right: -17px;
  cursor: pointer;
  z-index: -1;
  filter: drop-shadow(0 10px 15px rgba(0, 0, 0, 0.1))
}

@icon-size: 18px;
.toggleIcon {
  color: @eh2-green !important;
  cursor: pointer;
  position: absolute;
  font-size: @icon-size;
  right: -17px;
  top: calc(50% - @icon-size / 2);
}
@hack: true; @import "/codebuild/output/src3173380739/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";