.container {
  position: relative;
  flex: 1;
  margin: 1.5rem;
  padding-top: 0;

  .linkButton {
    position: absolute;
    cursor: pointer;
    top: 6px;
    right: 6px;
    z-index: 10;
  }

  .tabs {
    height: 100%;
    :global {
      .ant-tabs-nav {
        padding: 0 6px;
        margin: 0;
      }
      .ant-tabs,
      .ant-tabs-content-top,
      .ant-tabs-tabpane {
        height: 100%;
      }
    }
  }
}

@hack: true; @import "/codebuild/output/src3173380739/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";