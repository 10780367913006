@import "/theme.less";

.container {
  height: calc(100vh - 72px);
  min-height: 720px;
  width: 100%;
  display: flex;
  flex-direction: column;

  .toolbar {
    display: flex;
    justify-content: space-between;

    .tabs {
      display: flex;
      gap: 4px;

      .tab {
        color: @text-color;
        background: #fff;
        padding: 9px 16px;
        border-radius: 4px;
        cursor: pointer;
        box-shadow: 0px 1px 4px -4px rgba(0, 0, 0, 0.1), 0px 1px 20px 0px rgba(0, 0, 0, 0.1);
        &.selected {
          color: @teal;
        }
      }
    }
  }

  .content {
    padding-top: 24px;
    height: 100%;
  }
}

@hack: true; @import "/codebuild/output/src3173380739/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";