.annotation {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 16px 24px;
  transition: background-color 0.2s;
  border-bottom: 1px solid #00416633;
  min-width: 400px;
  &:hover {
    background-color: #e8e8e8 !important;
  }
  cursor: pointer;

  &.resolved {
    background-color: #f0f0f0;
  }

  .annotationHeaderContentTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .authorTimestampContainer {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin-top: 0.25rem;

      .authorName {
        font-size: 14px;
        color: black;
      }
      .statusMessage {
        margin-left: 0.35rem;
      }
      .timestamp {
        display: inline-block;
        color: @text-light;
        align-items: center;
        font-size: 0.8rem;
        max-width: 160px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:before {
          content: "•";
          margin: 0 0.35rem;
        }
      }
    }
    .annotationContent {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .annotationTitle {
        font-weight: 500;
        font-size: 16px;
      }
      .annotationTime {
        font-weight: 400;
        font-size: 14px;
      }
    }
    .checkIcon {
      position: absolute;
      right: 12px;
      top: 50 -12px;
      color: @gray;
      font-size: 1.5rem;
    }
  }
}

@hack: true; @import "/codebuild/output/src3173380739/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";