.organizationCodeContainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  p {
    margin: 0 0.5rem 0 0;
  }
  input {
    display: inline-block;
    width: auto;
  }
}
.organizationName {
  font-weight: 500;
}
.note {
  margin: 1rem 0 0 0;
  padding: 0.5rem 1rem;
  background: #FFFBE6;
  border: 1px solid #FFE58F;
  border-radius: 2px;
}

@hack: true; @import "/codebuild/output/src3173380739/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";