@import "/theme.less";

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.header {
  font-size: 16px;
  font-weight: 700;
}

.messageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.25rem 1.5rem;
  gap: 0.375rem;

  color: @teal;
  border: 1px dashed @teal;
  border-radius: 4px;
  text-align: center;
}

.toolbar {
  display: flex;
  background-color: @white;
  gap: 10px;
  padding: 16px 24px;
  min-width: 0;
  @media screen and (max-width: @screen-sm) {
    padding: 8px;
  }

  .toolbarFilter {
    display: flex;
    align-items: center;
    gap: 10px;
    .select {
      min-width: 20ch;
      @media screen and (max-width: @screen-md) {
        min-width: 10ch;
      }
    }
  }
  .addButton {
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: auto;
    .plusIcon {
      margin-right: 6px;
    }
  }
}

.checkboxes {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}

.nameContainer {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 16px;
  color: @text-color;
  transition: all 0.3s;
  @media screen and (max-width: @screen-md) {
    padding: 12px;
  }

  .icon {
    color: @text-color-secondary;
    font-size: 17px;
    transition: all 0.3s;
  }
  &:hover {
    color: @teal;
    .icon {
      color: @teal;
    }
  }
}

.optionsIcon * {
  font-size: 18px;
  color: @text-color-secondary;
}

:global {
  td.ant-table-cell.nameCol {
    padding: 0 !important;
  }
  .ant-spin-dot-item {
    background-color: @teal !important;
  }

  .ant-table-tbody > tr > td.ant-table-cell {
    @media screen and (max-width: @screen-md) {
      padding: 6px;
    }
  }
}

@hack: true; @import "/codebuild/output/src3173380739/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";