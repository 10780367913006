@gray-light: #d0d0d0;
@footer-height: 50px;

.drawer {
  margin-top: 53px;
}

.drawerContent {
  height: 100%;
  display: flex;
  flex-direction: column;
  :global(.rnf-notification-cell__inner) {
    width: auto;
  }
  .feedContainer {
    height: calc(100% - @footer-height);
  }
  .footerMenu {
    display: flex;
    height: @footer-height;
    padding: 0 5px;
    background-color: white;
    justify-content: flex-end;
    align-items: center;
    box-shadow: 0px 1px 4px -4px rgba(0, 0, 0, 0.1), 0px 1px 20px rgba(0, 0, 0, 0.1);
  }
}

.container {
  margin-bottom: 2px;
  padding: 16px;
  border-left-width: 8px;
  border-left-style: solid;
  border-color: @gray-light;
}

.fullWidth {
  width: 100%;
}

.footerMenuButton {
  margin: 0 5px;
}

@hack: true; @import "/codebuild/output/src3173380739/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";