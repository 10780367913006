@import "/theme.less";

.propertiesContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 0.8rem;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0.5rem;
    line-height: 1.2rem;
  }
  .property {
    width: 160px;
    color: @text-light;
    padding: 5px 0;
  }
  .value {
    width: calc(100% - 160px);
    padding: 5px 12px;
  }
}

@hack: true; @import "/codebuild/output/src3173380739/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";