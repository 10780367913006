.previewContainer {
  display: flex;
  justify-content: center;
  position: relative;
  margin-left: 1.25rem;
  height: 31.25rem;
  border-radius: 0.25rem;

  .panelContainer {
    width: min(68.75rem, 100%);
  }
  .spin {
    position: absolute;
    top: 12.5rem;
    left: 50%;
    transform: scale(1.3);
  }
}

.previewPlaceholder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: min(68.75rem, 100%);
  border: 0.0625rem solid rgba(0, 65, 102, 0.2);
  border-radius: 0.25rem;
  background-color: #fafafa;

  .placeholderContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    color: #bfbfbf;
    .previewTitle {
      font-size: 1rem;
    }
  }
}

@hack: true; @import "/codebuild/output/src3173380739/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";