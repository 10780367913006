.signatureContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  border: 1px solid #d9d9d9;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    border-color: @teal;
  }

  &:hover {
    .signatureImage {
      opacity: 50%;
    }
    .iconContainer {
      opacity: 100%;
    }
  }

  .addButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: @teal;
  }

  .signatureImageContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 3px;
    .signatureImage {
      height: 100%;
      max-width: 100%;
      transition: all 0.3s;
    }
  }

  .iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    color: @teal;
    font-size: 36px;
    opacity: 0;
    transition: all 0.3s;
  }
}

@hack: true; @import "/codebuild/output/src3173380739/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";