@import "/theme.less";

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  box-shadow: 0px 1px 4px -4px rgba(0, 0, 0, 0.1), 0px 1px 20px rgba(0, 0, 0, 0.1);
  background: @white;
  padding: 24px;
  @media screen and (max-width: @screen-md) {
    padding: 0;
  }

  .topSection {
    width: 100%;
    padding: 0;
    @media screen and (max-width: @screen-md) {
      padding: 12px;
      padding-bottom: 0;
    }

    .backContainer {
      display: flex;
      gap: 8px;
      align-items: center;
      color: @text-color-secondary;
      cursor: pointer;
      padding: 2px 0;
      width: fit-content;
      transition: color ease-in-out 0.1s;
      &:hover {
        color: @teal;
        transition: color ease-in-out 0.1s;
      }
    }
  }

  .topContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 0;

    @media screen and (max-width: @screen-xs) {
      flex-direction: column;
      gap: 14px;

      .buttonContainer {
        align-self: flex-end;
      }
    }

    .userDisplay {
      display: flex;
      gap: 10px;
      align-items: center;
      min-width: 0;
      max-width: 100%;
      flex: 1 1 auto;

      .avatar {
        flex-shrink: 0;
      }

      .userInfo {
        min-width: 200px;
        .name,
        .username {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 100%;
        }
      }
    }
  }

  .descriptionContainer {
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
}

@hack: true; @import "/codebuild/output/src3173380739/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";