.backContainer {
    display: flex;

  .backIcon {
    margin-right: 0.5rem;
  }
}
.content {
  margin-top: 1rem;

  .steps {
    margin-top: 1rem;
  }
  .stepContent {
    margin-top: 1rem;
    width: min(100%, 55rem);

    .dataSourcesContainer {
      display: flex;
      flex-direction: column;
      align-items: center;

      .dataSourceContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .dataSourceDivider {
          position: relative;
          margin: 0 0 1rem 0;
          max-width: 10rem;

          &:before {
            position: absolute;
            content: '';
            width: 3rem;
            height: 1px;
            background-color: #D9D9D9;
            top: 50%;
            right: 0;
            transform: translateY(-50%) translateX(-80%);
          }

          &:after {
            position: absolute;
            content: '';
            width: 3rem;
            height: 1px;
            background-color: #D9D9D9;
            top: 50%;
            left: 0;
            transform: translateY(-50%) translateX(80%);
          }
        }

        .dataSource {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          margin-bottom: 1rem;
          width: 100%;

          .dataSourceConditions {
            flex: 1;
            border: 1px solid rgba(0, 65, 102, 0.2);
            border-radius: 4px;
            padding: 1rem 1.5rem;

            .condition {
              display: flex;
              align-items: flex-start;
              gap: 0.75rem;

              > * {
                flex: 1;
                margin: 0;
              }
              .conditionValue {
                flex: 1;
                width: 10rem;
              }
              .conditionExtraSmall {
                flex: none;
                width: 4rem;
              }
              .conditionSmall {
                flex: none;
                width: 6rem;
              }
              .conditionMedium {
                flex: none;
                width: 9rem;
              }
              .conditionThreshold {
                flex: none;
                width: 10rem;
              }
              .conditionText {
                flex: none;
                align-self: flex-start;
                margin-top: 2.15rem;
              }
            }
          }
        }
      }
    }

  }
  .controls {
    display: flex;
    gap: 0.5rem;
    margin-top: 1rem;
  }
}
.notifyUsersSelect {
  :global(.ant-select-selector) {
    min-height: 3rem;
    :global(.ant-select-selection-item) {
      display: flex;
      align-items: center;
      height: 2.25rem;
      padding: 0 0.5rem;
    }
  }
}
.notifyUsersAvatar {
  margin-right: 0.25rem;
}
.sideBySide {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  > * {
    flex: 1;
  }
}

@hack: true; @import "/codebuild/output/src3173380739/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";