.annotationHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid @bg-light;
  .annotationHeaderTitleContainer {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .checkIcon {
      color: @teal;
      font-size: 1.5rem;
    }
    .annotationHeaderTitle {
      margin: 0;
    }
  }
  .closeAnnotation {
    font-size: 1rem;
    color: @gray;
    cursor: pointer;
  }
}
.annotationHeaderContentContainer {
  flex: 0 1 auto;
}
.annotationTimestamp {
  font-size: 15px;
  padding: 0.5rem 1rem 0;
}
.annotationContent {
  padding: 0 1rem 0.5rem;

  .annotationParagraph {
    margin: 0.7rem 0 0.3rem;
  }
  .annotationMeasures {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem 0.25rem;
  }
}
.scrollContainer {
  display: block;
  overflow-y: scroll;
}

.annotationMessagesContainer {
  display: flex;
  flex-flow: column-reverse;
  justify-content: flex-end;
  flex: 1 1 auto;
  overflow-y: auto;
  gap: 0.75rem;
  padding: 1rem;
}
.messageForm {
  flex: 0 1 170px;
  margin: 0 1rem !important;
}
.actionButtons {
  display: flex;
  justify-content: flex-starts;
  gap: 0.5rem;
}
.annotationMessageInput {
  margin: 0 0 0.5rem;
}
.mentionHighlight {
  background-color: @teal-medium;
}

@hack: true; @import "/codebuild/output/src3173380739/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";