@import "/theme.less";

.container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .bannerText {
    position: relative;
    padding: 1.25rem 1.5rem 0.5rem 1.5rem;
    color: white;

    z-index: 3;
    @media screen and (max-width: @screen-xs) {
      padding: 1rem;
      font-size: 26px;
    }

    .bannerTitle {
      font-weight: 500;
      font-size: 30px;
    }
    .bannerSubtitle {
      padding-top: 0.75rem;
      font-size: 16px;
      font-weight: 700;
    }
  }
  .topBanner {
    position: absolute;
    left: 0;
    right: 0;
    height: 11.625rem;
    overflow: hidden;
    margin-bottom: -5.625rem;
    z-index: 0;

    background-size: cover;
    border-radius: 0rem 0rem 1rem 1rem;
  }
  .cardsContainer {
    align-self: center;
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
    width: 100%;
    max-width: 2000px;
    padding: 24px;
    @media screen and (min-width: 900px) {
      grid-template-columns: repeat(2, 1fr);
    }
    .card {
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     gap: 24px;
     min-height: 300px;
     padding: 24px;
     border-radius: 4px;

     .iconContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      font-size: 16px;
      font-weight: 500;
     }
     .contact {
      font-size: 20px;
      line-height: 2;
      font-weight: 500;
      text-align: center;
     }

     .buttonsContainer {
      display: flex;
      gap: 12px;
     }
    }
  }
  .disclaimer {
    justify-self: flex-end;
    align-self: center;
    padding: 24px;
    padding-top: 48px;
    font-size: 16px;
    color: @text-color-secondary;
  }
}

.button {
  background: @eh2-green;
}

@hack: true; @import "/codebuild/output/src3173380739/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";