@import "/theme.less";
.actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  .actionLink {
    padding: 0;
    color: @link-color !important;
    &:hover {
      color: @link-hover-color !important;
    }
  }
}

.actionsSmallContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 8px;
  gap: 8px;
}

.dropdownButtonContainer {
  display: flex;
  justify-content: center;
}

@hack: true; @import "/codebuild/output/src3173380739/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";