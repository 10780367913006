@import "/theme.less";

.favoriteButtonContainer {
  display: flex;
  justify-content: center;
}

.messageContainer {
  align-items: center;
  padding: 0.75rem 1.5rem;
  gap: 0.625rem;

  color: @teal;
  border: 1px dashed @teal;
  border-radius: 4px;
  text-align: center;

  .favoriteIcon {
    stroke: #f3b416;
    :global(#heart) {
      stroke: #f3b416;
      fill: #f3b416;
    }
  }

  .link {
    color: @teal;
    font-weight: 600;
    &:hover {
      text-decoration: underline !important;
    }
  }
}

@hack: true; @import "/codebuild/output/src3173380739/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";