.container {
  margin-top: 2rem;
  .paragraph {
    margin-bottom: 0.5rem;
  }
  .required {
    color: #ff0f0f;
  }
  .stepIndicator {
    width: min(100%, 48rem);
    margin: 2rem 0 !important;
  }
  .stepContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .stepOrganizations {
    width: min(100%, 30rem);

    .organizationSelect {
      width: 100%;
    }
    .organizationNext {
      display: flex;
      gap: 0.5rem;
      margin: 1rem 0;
    }
  }
  .stepMeasures {
    width: 100%;
  }
  .stepSummary {
    width: 100%;

    .paragraph {
      font-weight: 500;
    }
    .sharingCompanies {
      margin-left: 2rem;
      font-weight: normal;
    }
    .allMeasuresText {
      margin-left: 3.3rem;
      font-weight: normal;
    }
  }
}
.backActionContainer {
  display: flex;
}

.drawer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: auto;
  height: 60px;
  z-index: 600;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0 1rem;
  transform: translateY(100px);
  background-color: white;
  box-shadow: 0px -3px 6px -4px rgba(0, 0, 0, 0.12), 0px -6px 16px rgba(0, 0, 0, 0.08),
    0px -9px 28px 8px rgba(0, 0, 0, 0.05);
  transition: transform 0.15s ease-out, left 0.2s;

  &.open {
    display: flex;
    transform: translateY(0px);
  }
  .paragraph {
    margin: 0;
  }
  .buttonContainer {
    display: flex;
    gap: 0.5rem;
  }
}

@media screen and (min-width: @screen-xxl) {
  .container {
    .stepIndicator {
      width: min(54%, 66rem);
    }
    .stepOrganizations {
      width: min(34%, 40rem);
    }
  }
}

:global(.ant-steps-finish-icon) {
  color: @primary-color !important;
}
:global(.ant-table-pagination) {
  justify-content: center !important;
}

@hack: true; @import "/codebuild/output/src3173380739/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";