@import "/theme.less";

@icon-size: 18px;

a {
  text-decoration: none !important;
}
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: fixed;
  right: 0;
  padding: 0 12px;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  font-size: @icon-size;
  z-index: 10;
}

.headerMenu {
  :global(.anticon) {
    margin-right: 8px;
  }
  :global(.ant-dropdown-menu-item) {
    min-width: 160px;
  }
}

.notificationsIcon {
  text-decoration: none;
}

.trigger {
  font-size: 20px;
  line-height: 64px;
  cursor: pointer;
  transition: all 0.3s, padding 0s;
  padding: 0 24px;
  &:hover {
    color: @primary-color;
  }
}

.globalHeaderLeft {
  display: flex;
  align-items: center;
  gap: 12px;
  transition: all 0.3s;
}

.globalHeaderRight {
  display: flex;
  align-items: center;
  float: right;
  height: 100%;
  overflow: hidden;
  .action {
    display: flex;
    height: 100%;
    padding: 0 12px;
    cursor: pointer;
    transition: all 0.3s;
    > i {
      color: @text-color;
      vertical-align: middle;
      text-rendering: optimizeLegibility;
      -webkit-font-smoothing: antialiased;
    }
  }
  .search {
    padding: 0 12px;
    &:hover {
      background: transparent;
    }
  }

  .notificationsIcon {
    font-size: @icon-size;
  }
  .icon {
    margin-left: 10px;
    margin-right: 2px;
  }

  @media screen and (max-width: @screen-xs) {
    .dropdownContainer > * {
      border-radius: 0 !important;
    }
  }

  @media only screen and (max-width: @screen-md) {
    .header {
      :global(.ant-divider-vertical) {
        vertical-align: unset;
      }
      i.trigger {
        padding: 0 12px;
      }
    }
  }
}

@hack: true; @import "/codebuild/output/src3173380739/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";