@import "/theme.less";

.container {
  height: 100%;
  padding-bottom: 4rem;
  :global(.ant-form) {
    margin-top: 2rem !important;
  }
}

:global {
  .ant-table-filter-dropdown {
    .ant-radio-inner {
      display: none !important;
    }
    .ant-radio-wrapper {
      margin-inline-end: 0 !important;
    }
    .ant-dropdown-menu-title-content,
    .ant-dropdown-menu-title-content > span {
      display: flex !important;
      align-items: center !important;
    }
  }
}

.contentContainer {
  display: flex;
  flex-direction: row;

  .previewSection {
    width: 60%;
  }
  .tableSection {
    width: 40%;
  }
  .settingsButtonContainer {
    display: flex;
    justify-content: flex-end;
    height: 48px;
    padding-bottom: 16px;
  }
}
.tableFilters {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

.titleActionContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.5rem;

  .editTitleContainer {
    display: flex;
    align-items: center;
    width: 100%;

    .titleFormItem {
      margin: 0;
      width: 100%;
    }
    input.editTitle {
      width: 100%;
      padding: 0;
      font-size: 1.5rem;
      font-weight: 500;
      border-color: transparent;
      caret-color: @teal;
      cursor: default;
      border-radius: 0;
      resize: horizontal;
    }
    input.editTitle:hover,
    input.editTitle:focus {
      border-color: transparent;
      box-shadow: none;
    }
    input.editTitle:focus {
      cursor: text;
    }
    .editTitleButton {
      padding: 4px 15px 4px 0 !important;
      svg {
        font-size: 1.5rem;
        color: @teal;
      }
    }
  }
}
.backActionContainer {
  display: flex;

  .actionButtons {
    display: flex;
    gap: 0.5rem;
    margin-left: auto;
  }
}
.measuresCard {
  box-shadow: none;
  margin-top: 2rem;
  padding: 0;
}
.measurePlaceholder {
  color: @text-disabled;
  display: inline-block;
  height: 24px;
}
.tabs {
  :global(.ant-tabs-nav) {
    margin: 0 !important;
    &::before {
      border: 0 !important;
    }
  }
  .tabpane {
    background: @white;
  }
  :global(.ant-tabs-tab) {
    padding: 0.7rem 2rem !important;
    border: 0 !important;
    border-radius: 0 !important;
    background: @white !important;
    color: @text-light;
  }
  :global(.ant-tabs-tab.ant-tabs-tab-active) {
    background: #fafafa !important;
  }
  :global(.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn) {
    color: @text-color !important;
  }
  :global(.ant-pagination) {
    display: flex;
    justify-content: center;
  }
}

@hack: true; @import "/codebuild/output/src3173380739/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";