@import "/theme.less";

.actionsContainer {
  display: flex;
  gap: 0.5rem;
  @media screen and (max-width: @screen-md) {
    flex-direction: column;
    align-items: center;
  }
}

td.titleColumn {
  padding: 0 !important;
}

.titleContainer {
  display: flex;
  padding: 1rem;
  color: @text-color;
  cursor: pointer;
  &:hover {
    color: @teal;
  }
}

.draft {
  color: @teal !important;
  background: @teal-light !important;
  border-color: #80af94 !important;
}

.review {
  color: #fa8c16 !important;
  background: #fff7e6 !important;
  border-color: #ffd591 !important;
}

.done {
  color: #52c41a !important;
  background: #f6ffed !important;
  border-color: #b7eb8f !important;
}

@hack: true; @import "/codebuild/output/src3173380739/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";