@import "/theme.less";

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .topContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 24px;
      font-weight: 500;
    }

    .addButton {
      min-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: auto;
      .plusIcon {
        margin-right: 6px;
      }
    }
  }
}

@hack: true; @import "/codebuild/output/src3173380739/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";