.center {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.iconContainer {
  display: flex;
}

.dropdownButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

@hack: true; @import "/codebuild/output/src3173380739/src/github.com/Electric-Hydrogen/lifteware-web/theme.less";